/* Your app custom styles here */

.page-content {
  margin:15px;
  text-align:center;
}

h1 {
  font-size: 38px;
}

.sand-button {
  cursor:pointer;
  border-radius:15px;
  background-color:var(--f7-color-blue);
  font-size:100px;
}